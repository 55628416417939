.carousel {

	.flickity-prev-next-button {

		@apply bg-transparent #{!important};
		@apply rounded-none;
		@apply h-full;
		width: 11%;

		&.next {

			@apply right-0;
		}

		&.previous {

			@apply left-0;
		}


		svg {

			@apply hidden;
		}
	}

	.item {

		@apply opacity-30;
		@apply transition-opacity duration-300;

		&.is-selected {

			@apply opacity-100;
		}
	}
}
