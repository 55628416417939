/* stylelint-disable selector-max-compound-selectors */

/*==========================================================================*\
  Global
\*==========================================================================*/

/* Base
\*==========================================================================*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

*,
*::before,
*::after {
	position: relative;
}

* {

	&:focus,
	&:focus-visible {
		outline: none !important;
		box-shadow: none !important;
	}
}

html {
	overflow-x: hidden;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
	scroll-behavior: smooth;

	@apply bg-gray-900;
	@apply text-white;
}

body {

	@apply font-sans;
	overflow-x: hidden;
}

h1,
h2,
h3,
h4 {

	@apply font-serif;
	@apply font-normal;

	strong {

		@apply font-bold;
		@apply font-sans;
	}

	&:not(:last-child) {

		@apply mb-8;

		@screen s {

			@apply mb-12;
		}
	}
}

h2 {

	@apply uppercase;
	@apply text-7xl;
	line-height: 0.85;

	@screen s {

		@apply text-8xl;
		line-height: 0.85;
	}

	@screen m {

		@apply text-9xl;
		line-height: 0.85;
	}
}

h3 {

	@apply text-4xl;
	line-height: 0.85;

	@screen s {

		@apply text-5xl;
		line-height: 0.85;
	}
}

h4 {

	@apply text-2xl;

	@screen s {
		font-size: 2rem;
		line-height: 1.3125;
	}
}

p {

	&:not(:last-child) {

		@apply mb-6;
	}
}

// Inline tag
strong,
b {
	font-weight: 700;
}

em {
	font-style: italic;
}

a {
	text-decoration: none;
	cursor: pointer;
}

// Image and figure
img {
	height: auto;
}

/* Selection
\*==========================================================================*/

::-moz-selection {
	text-shadow: none;

	@apply text-white;
	@apply bg-black;
}

::selection {
	text-shadow: none;

	@apply text-white;
	@apply bg-black;
}
