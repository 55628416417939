.media {

	&.js-reveal {

		@apply opacity-0;
		@apply transition duration-500;

		&.zoom-in {

			@apply scale-95;
			transition-property: opacity, transform;
			transition-timing-function: ease-in-out, theme("transitionTimingFunction.in-out-circ");
			transition-duration: 0.4s, 0.5s;
		}

		&.zoom-out {

			@apply scale-105;
			transition-property: opacity, transform;
			transition-timing-function: ease-in-out, theme("transitionTimingFunction.out-expo");
			transition-duration: 0.4s, 1s;
		}

		&.is-visible.loaded,
		&.is-visible.img-is-loaded {

			@apply opacity-100;

			&.zoom-in,
			&.zoom-out {

				@apply scale-100;
			}

		}
	}
}
