/*============================================================================*\
  Styles
\*============================================================================*/
@import "config";

/* Utilities
\*============================================================================*/
@tailwind utilities;

/* Globals
\*============================================================================*/

@tailwind base;
@import "base/global";
@import "base/header";
@import "base/footer";

/* Elements
\*============================================================================*/

@import "elements/icons";
@import "elements/btn";
@import "elements/form";
@import "elements/wysiwyg";

/* Components
\*============================================================================*/
@tailwind components;
@import "components/carousel";
@import "components/media";
@import "components/reveal";

/* Vendors
\*============================================================================*/
@import "vendors/flickity";
