.btn {

	@apply inline-flex items-center;
	@apply cursor-pointer;
	@apply text-sm uppercase font-semibold;
	@apply h-10;
	@apply px-6;
	@apply border border-white rounded-full border-opacity-50;
	@apply transition duration-300;

	&:hover {

		@apply bg-white;
		@apply text-gray-900;
		@apply border-opacity-100;
	}
}
