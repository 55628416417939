input[type="text"],
input[type="email"],
textarea {

	@apply block;
	@apply w-full;
	@apply appearance-none;
	@apply rounded-none;
	@apply px-4 py-3;
	@apply bg-transparent;
	@apply border-b border-white border-opacity-30;
	@apply resize-none;
	@apply text-white text-opacity-30;

	@screen s {

		@apply px-6 py-5;
	}

	&::placeholder {

		@apply text-white text-opacity-30;
	}
}

.form-label {

	@apply pl-6;

	&::before {
		content: "";

		@apply absolute top-2.5 -translate-y-1/2 left-0;
		@apply rounded-full;
		@apply w-2 h-2;
		@apply border border-current;
	}
}

.msg-form {

	.msg {

		@apply mb-8;
		@apply bg-pink-200;
		@apply px-4 py-3;
		@apply uppercase font-semibold text-sm;
		@apply text-gray-900;

		@screen s {

			@apply mb-12;
		}
	}
}


form {

	&.loaded {

		@apply pointer-events-none;
	}

	altcha-widget {

		@apply text-xs font-semibold uppercase;

		[id="altcha_checkbox"] {

			@apply block;
			@apply w-4 h-4;
			@apply bg-transparent;
			@apply appearance-none;
			@apply border border-current;

			&::before {
				content: "";

				@apply absolute;
				@apply top-1/2 left-1/2;
				@apply -translate-x-1/2 -translate-y-1/2;
				@apply bg-white;
				@apply w-2 h-2;
				@apply scale-0;
				@apply transition-all duration-300;
			}

			&:checked {

				&::before {

					@apply scale-100;
				}
			}
		}

		.altcha-main {

			@apply p-0 #{!important};
		}

		.altcha-logo {

			@apply hidden #{!important};
		}
	}
}

:root {
	--altcha-border-width: 0;
	--altcha-border-radius: 0;
	--altcha-color-base: transparent;
	--altcha-color-border: #a0a0a0;
	--altcha-color-text: currentColor;
	--altcha-color-border-focus: currentColor;
	--altcha-color-error-text: #f23939;
	--altcha-color-footer-bg: #f4f4f4;
	--altcha-max-width: 260px;
}
